import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// Pages import Here
import HomePage from "./pages/HomePage";
import LegalNotice from "./pages/LegalNotice";
import JoinUs from "./pages/JoinUs";
import Error from "./pages/Error";
import AWSPage from "./pages/aws";
import OVHPage from "./pages/ovh";

// Import Css Here
import './assets/scss/style.scss';



const App = () => {

    React.useEffect(() => {
        let _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://a.cockpitio.com/js/container_WxEYaj1l.js'; s.parentNode.insertBefore(g,s);
    }, [])

    return (
        <Router>
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL + "/expertise-aws"}`} element={<AWSPage/>}/>
                    <Route path={`${process.env.PUBLIC_URL + "/expertise-ovh"}`} element={<OVHPage/>}/>
                    <Route path={`${process.env.PUBLIC_URL + "/mentions-legales"}`} element={<LegalNotice/>}/>
                    <Route path={`${process.env.PUBLIC_URL + "/join-us"}`} element={<JoinUs/>}/>
                    <Route path="*" element={<Error />} />
                </Routes>
        </Router>
    )
}

export default App