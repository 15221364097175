import React from 'react';
import {ReactTyped} from 'react-typed';
import SidebarTag from "../../components/blog/sidebar/SidebarTag";

const AboutUs = () => {
    return (
        <div className="rwt-about-area rn-section-gap" id="about-us">
            <div className="container">

                <div className="row row--30 align-items-center">
                    <div className="col-md-4 .offset-md-4"></div>

                    <div className="col-lg-8 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Devenez <br/> + {" "}
                                    <ReactTyped className="theme-gradient"
                                                strings={[
                                                    "Compétitifs",
                                                    "Attractifs",
                                                    "Innovants",
                                                ]}
                                                typeSpeed={80}
                                                backSpeed={5}
                                                backDelay={1000}
                                                loop
                                    >
                                    </ReactTyped>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row--30 align-items-center row-top-padding-0">
                    <div className="col-lg-4">
                        <div className="thumbnail">
                            <SidebarTag />
                        </div>
                    </div>
                    <div className="col-lg-8 mt_md--40 mt_sm--40">
                        <div className="content">

                            <div className="section-title">
                                <p>Cockpit io est une équipe formée d’expert·e·s techniques qui s’appuie sur un écosystème de  partenaires privilégiés, pour vous aider à transformer, moderniser et optimiser vos infrastructures cloud !</p>
                                <div>
                                    <p>Notre différenciation se décline sur 3 axes :</p>
                                    <ul>
                                        <li>Nos technologies innovantes et éprouvées</li>
                                        <li>Nos méthodologies structurantes</li>
                                        <li>Notre veille technologique continue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutUs;
