import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {GiEcology, GiInfinity, GiShipWheel, GiPadlock} from "react-icons/gi";

const ServiceList = [
    {
        icon: <GiInfinity />,
        title: 'DevOps',
        description: 'Respect des bonnes pratiques et des méthodologies DevOps.'
    },
    {
        icon: <GiPadlock />,
        title: 'Sécurité',
        description: 'Respect des normes et des bonnes pratiques de sécurité.'
    },
    {
        icon: <GiEcology />,
        title: 'Responsabilité',
        description: 'Usage responsable des ressources (Green IT) et optimisation des coûts (FinOps).'
    },
    {
        icon: <GiShipWheel />,
        title: 'Gouvernance',
        description: 'Gouvernance intégrant toutes les parties prenantes'
    },
]

const GoldenRules = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInDown"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <div className="line"></div>
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default GoldenRules;