import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { GiPaperPlane } from "react-icons/gi";
import SectionTitle from "../sectionTitle/SectionTitle";
import BrandFour from "../brand/BrandFour";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap" id="expertises">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation
                                    animateIn="fadeInDown"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>

                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Nos projets types"
                                        description = ""
                                    />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <ul className="list-icon">
                            <li><span className="icon"><GiPaperPlane /></span> Conception et build d’architectures cloud</li>
                            <li><span className="icon"><GiPaperPlane /></span> Conteneurisation et migration vers Kubernetes</li>
                            <li><span className="icon"><GiPaperPlane /></span> Mise en place de projets Serverless</li>
                            <li><span className="icon"><GiPaperPlane /></span> Mise en place d’usines logicielle</li>
                            <li><span className="icon"><GiPaperPlane /></span> Mise en place de pipelines de CI/CD</li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="list-icon">
                            <li><span className="icon"><GiPaperPlane /></span> Mise en place de solution d’observabilité</li>
                            <li><span className="icon"><GiPaperPlane /></span> Chaos engineering & Tests de charge</li>
                            <li><span className="icon"><GiPaperPlane /></span> Audit</li>
                            <li><span className="icon"><GiPaperPlane /></span> Migration vers le cloud</li>
                            <li><span className="icon"><GiPaperPlane /></span> Formations personnalisées</li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mt--40">
                        <BrandFour brandStyle="brand-style-2 variation-2" />
                    </div>
                </div>

                <div className="row">
                    <div className="text-center mt-5">
                        <a className="join-us-btn btn-default btn-icon" href="/expertise-aws">En savoir plus sur notre
                            partenariat AWS</a>
                    </div>
                    <div className="text-center mt-5">
                        <a className="join-us-btn btn-default btn-icon" href="/expertise-ovh">En savoir plus sur notre
                            partenariat OVH</a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutTwo;
