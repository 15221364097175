import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";


const LegalNotice = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('light');
    }, []);

    return (
        <>
            <SEO title="Cockpit io | Mentions légales" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                <div className="post-page-banner rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="content text-center">
                                    <div className="page-title">
                                        <h1 className="theme-gradient">Mentions légales</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-details-content pt--60 rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="content">
                                    <p>
                                        Conformément à l’article n°6 de la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet www.cockpitio.com sont :
                                    </p>

                                    <h2>Article 1 - Éditeur du Site</h2>
                                    <p>
                                        SAS Cockpit io Numéro de SIRET : 91486264400012 au capital social de 20 000 euros,
                                        numéro TVA intracommunautaire FR07914862644, représentée par Katia HIMEUR en sa qualité de Présidente et Édouard RODRIGUEZ en sa qualité de directeur général.
                                    </p>
                                    <p>
                                        <b>Adresse :</b> 10 rue de Penthièvre, 75008 Paris <br/>
                                        <b>Email :</b> contact@cockpitio.com <br/>
                                        <b>Site Web :</b> www.cockpitio.com <br/>
                                        <b>Hébergement :</b>  Amazon Web Services, Inc. P.O. Box 81226 Seattle, WA 98108-1226 USA
                                    </p>

                                    <h2>Article 2 - Conditions d’utilisation</h2>
                                    <p>
                                        La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et des conditions d’utilisation qui suivent.
                                    </p>
                                    <p>
                                        La version actuellement en ligne de ces conditions d’utilisation est la seule opposable pendant toute la durée d’utilisation du site et jusqu’à ce qu’une nouvelle version la remplace.
                                    </p>

                                    <h2>Article 3 - Cookies</h2>
                                    <p>
                                        Le site www.cockpitio.com peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d’affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
                                    </p>

                                    <h2>Article 4 - Liens hypertextes</h2>
                                    <p>
                                        La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l’éditeur. Tout lien devra être retiré sur simple demande de l’éditeur.
                                    </p>
                                    <p>
                                        Toute information accessible via un lien vers d’autres sites n’est pas publiée par l’éditeur. L’éditeur ne dispose d’aucun droit sur le contenu présent dans ledit lien.
                                    </p>

                                    <h2>Article 5 - Gestion du site</h2>
                                    <p>
                                        Pour la bonne gestion du site, l’éditeur pourra à tout moment :
                                    </p>
                                    <ul>
                                        <li>Suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internautes ; </li>
                                        <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales ; </li>
                                        <li>Suspendre le site afin de procéder à des mises à jour
                                        </li>
                                    </ul>

                                    <h2>Article 6 - Responsabilités</h2>
                                    <p>
                                        La responsabilité de l’éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l’accès au site ou une de ses fonctionnalités.
                                    </p>
                                    <p>
                                        Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données, notamment d’attaques virales par Internet. Vous êtes par ailleurs seul responsable des sites et données que vous consultez.
                                    </p>
                                    <p>
                                        L’éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
                                    </p>
                                    <ul>
                                        <li>Du fait de l’usage du site ou de tout service accessible via Internet ; </li>
                                        <li>Du fait du non-respect par vous des présentes conditions générales. </li>
                                    </ul>
                                    <p>
                                        L’éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
                                    </p>
                                    <ul>
                                        <li>Du fait de l’usage du site ou de tout service accessible via Internet ;</li>
                                        <li>Du fait du non-respect par vous des présentes conditions générales.</li>
                                    </ul>
                                    <p>
                                        L’éditeur n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.
                                    </p>
                                    <p>
                                        Si l’éditeur venait à faire l’objet d’une procédure amiable ou judiciaire en raison de votre utilisation du site, il pourra se retourner contre vous pour obtenir l’indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
                                    </p>
                                    <p>
                                        Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions.
                                    </p>
                                    <p>
                                        Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par courriel, à l’adresse contact@cockpitio.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).
                                    </p>

                                    <h2>Article 7 - Propriété intellectuelle </h2>
                                    <p>
                                        Tout le contenu du présent site www.cockpitio.com, incluant, de façon non limitative, les graphismes, les images, les textes, les vidéos, les animations, les sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.
                                    </p>
                                    <p>
                                        Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de SAS Test. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
                                    </p>

                                    <h2>Article 8 - Données personnelles </h2>
                                    <p>
                                        Les données personnelles en France sont régies par la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l’égard des traitements de données à caractère personnel) relative à l’informatique, aux fichiers et aux libertés.
                                    </p>
                                    <p>
                                        À l’utilisation du site www.cockpitio.com, seuls les adresses IP de l’utilisateur, le fournisseur d’accès et les liens par l’intermédiaire desquels l’utilisateur a accédé au site sont collectés.  Le site n'est pas déclaré à la CNIL, car il ne recueille pas d'informations personnelles.
                                    </p>
                                    <p>
                                        Les informations collectées sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
                                    </p>
                                    <p>
                                        De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet www.cockpitio.com.
                                    </p>
                                    <p>
                                        Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ».
                                    </p>
                                    <p>
                                        Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.
                                    </p>

                                    <h2>Article 9 - Loi applicable</h2>
                                    <p>
                                        Les présentes conditions d’utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux du siège social de l’éditeur, sous réserve d’une attribution de compétences spécifique découlant d’un texte de loi ou réglementaire particulier.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    )
}
export default LegalNotice;
