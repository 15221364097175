import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from "react-router-dom";
import {FaRegLightbulb} from "react-icons/fa";
import {MdOutlineSchool} from "react-icons/md";
import {VscRocket} from "react-icons/vsc";


const dataList = [
    {
        title: 'Audit',
        description: '<ul><li><b>Immersion</b> dans vos activités et <b>compréhension</b> de vos enjeux en intégrant l’ensemble des acteurs du projet.</li>' +
            '<li>Grâce à un ensemble d’interviews, nous affinons la compréhension et la définition de vos <b>besoins</b> et de vos <b>attentes</b>.</li>' +
            '<li>Nous auditons vos pratiques et vos environnements techniques.</li></ul>'
    },
    {
        title: 'Étude & Benchmark',
        description: '<ul><li>Nous analysons votre <b>existant</b> pour le mettre en perspective par rapport aux <b>standards</b> du marché et des méthodologies DevOps.</li>' +
            '<li>Nous vous aidons à identifier vos <b>points forts</b> et les axes sur lesquels il faut capitaliser.</li>' +
            '<li>Nous vous aidons également à identifier vos <b>axes d’amélioration</b> et d’évolution.</li></ul>'
    },
    {
        title: 'Préconisations & plan d’actions',
        description: '<ul><li>Nous définissons avec et pour vous des architectures et des solutions <b>résilientes</b>, <b>scalables</b> et <b>hautement disponibles</b>.</li> ' +
            '<li>Nous œuvrons dans le respect des <b>standards</b> du <b>cloud</b> et des pratiques <b>DevOps</b>.</li> ' +
            '<li>Nous faisons évoluer vos pratiques et vos infrastructures en embarquant vos équipes.</li></ul>'
    }
]

const dataList2 = [
    {
        title: 'Adopter',
        description: '<ul><li>Vous souhaitez <b>migrer vers le cloud</b>, nous définissons des architectures en cohérence avec vos besoins et vos contraintes. </li>' +
            '<li>Grâce à notre approche personnalisée et collaborative, nous construisons des infrastructures cloud <b>évolutives</b> et <b>sécurisées</b>.</li></ul>'
    },
    {
        title: 'Accélerer',
        description: '<ul><li>Vous êtes déjà utilisateur du cloud et vous souhaitez <b>optimiser</b> vos usages et vos coûts.</li>' +
            '<li>Nos expériences et nos expertises, nous permettrons de vous aider à atteindre ces objectifs.</li></ul>'
    },
    {
        title: 'Transformer',
        description: '<ul><li><b>Adoption</b> de la culture et des pratiques <b>DevOps</b>.</li>' +
            '<li>Mise en place d’usines logicielles et de pipelines de <b>CI/CD</b>.</li>' +
            '<li><b>Refonte</b> des workflows, des pratiques et des infrastructures.</li></ul>'
    }
]

const dataList3 = [
    {
        title: 'Nos formations',
        description: 'Ateliers d’acculturation DevOps et formations aux outils de l’écosystème cloud(Terraform, Istio, Kubernetes...).'
    },
    {
        title: 'Notre pédagogie',
        description: 'Nos formations combinent théorie, pratique et retours d’expérience pour faire monter en compétences vos équipes.'
    },
    {
        title: 'Nos formats',
        description: 'Des plans de formation sur mesure dispensés par nos expert·e·s avec une fréquence adaptée à vos besoins opérationnels et organisationnels.'
    }
]

const TabThree = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="rn-default-tab style-three">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button><FaRegLightbulb /> <b>Conseil</b></button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button><VscRocket /> <b>Build</b></button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button><MdOutlineSchool /> <b>Formation</b></button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-12">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList.map( (val , i) => (
                                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--10" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-12">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList2.map( (val , i) => (
                                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-12">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList3.map( (val , i) => (
                                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabThree
