import React, {useEffect} from 'react';
import {ReactTyped} from 'react-typed';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import AboutUs from '../elements/about/AboutUs';
import CalltoActionHome from '../elements/calltoaction/CalltoActionHome';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TabThree from "../elements/tab/TabThree";
import Contact from "../elements/contact/Contact";
import GoldenRules from "../elements/core/GoldenRules ";
import AboutTwo from "../elements/about/AboutTwo";
import Copyright from "../common/footer/Copyright";


const HomePage = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('light');
    }, []);

    return (
        <>
            <SEO title="Cockpit io | Conseil et expertise Cloud & DevOps" />
            <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
            <main className="page-wrapper">
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-600">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Conseil<br/> {" "}
                                        <ReactTyped
                                            strings={[
                                                "Cloud",
                                                "DevOps",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            >
                                    </ReactTyped>
                                </h1>
                                    <p className="description">Une équipe de passionné·e·s, guidé·e·s par la culture DevOps, pour vous offrir le meilleur du Cloud.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img className="logo-dark" src="./images/bg/cockpitio-team-light.png" alt="Cockpit io illustration" />
                                    <img className="logo-light" src="./images/bg/cockpitio-team-dark.png" alt="Cockpit io illustration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start About Area  */}
                <AboutUs />
                {/* End About Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rwt-tab-area rn-section-gap" id="offers">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Créons de la valeur ensemble<br/>
                                            Quelque soit le stade de votre projet
                                            "
                                    description = ""
                                />
                                <div className="text-center">
                                    <p className="text-style">Faire évoluer vos pratiques, réduire votre time to market, fiabiliser vos processus de livraison, fidéliser et attirer de nouveaux talents… sont autant d’objectifs que vous souhaitez atteindre.</p>
                                    <p className="text-style">Profitez de nos expertises, de nos savoirs-faire et de nos expériences réussies.</p>
                                </div>
                            </div>
                        </div>
                        <TabThree />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <AboutTwo />

                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Pour la parfaite exécution de vos projets <br/>
                                             Nos 4 règles d'or"
                                    description = ""
                                />
                            </div>
                        </div>
                        <GoldenRules
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rwt-callto-action-area mb--30" id="join-us">
                    <div className="wrapper">
                        <CalltoActionHome />
                    </div>
                </div>

                <Separator />
                
                <Contact />

                <Separator />

                <Copyright />

            </main>
        </>
    )
}
export default HomePage;
