import React from 'react';


const callToActionData = {
    title: "Rejoignez-nous !",
    subtitle: "Embarquez dans le cockpit !",
    btnText: "join-us@cockpitio.com",
}

const CalltoActionJoinUs = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h1 className="title">{callToActionData.title}</h1>

                                <h6 className="subtitle">{callToActionData.subtitle}</h6>

                                <div className="call-to-btn text-center">
                                    <a className="join-us-btn btn-default btn-icon" href="mailto:join-us@cockpitio.com">{callToActionData.btnText} </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionJoinUs;