import React, {useEffect} from 'react';
import {ReactTyped} from 'react-typed';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import Copyright from "../common/footer/Copyright";
import AboutOVH from '../elements/ovh/AboutOVH';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import References from "../elements/ovh/references";


const OVHPage = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('light');
    }, []);

    return (
        <>
            <SEO title="Cockpit io | Expertes & experts Cloud OVH & Souverain" />
            <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
            <main className="page-wrapper">

             {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-650">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <img className="text-center mb--30 " src="../images/expertises/cockpit-io-ovh.jpg" alt="Cockpit io OVH partnership illustration" />
                                    <span className="subtitle">Profitez de notre partenariat pour :</span>
                                    <h1 className="title theme-gradient display-two">{" "}
                                        <ReactTyped
                                            strings={[
                                                "Innover",
                                                "Performer",
                                                "Fidéliser",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        >
                                        </ReactTyped>
                                    </h1>
                                    <p className="description">Bénéficiez de nos expertises, de nos success stories et de notre équipe certifiée pour profiter pleinement de la puissance du cloud OVH !</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                 <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Nos domaines d'intervention"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <References
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign = "text-start"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start About Area  */}
                <AboutOVH />
                {/* End About Area  */}

                <Separator />

                <Copyright />

            </main>
        </>
    )
}
export default OVHPage;
