import React from 'react';
import Copyright from "./footer/Copyright";
import HeaderTwo from "./header/HeaderTwo";

const Layout = ({children}) => {

    return (
        <>
            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
                {children}
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
