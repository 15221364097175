import React from 'react';
import { BsArrowUpRightSquare } from "react-icons/bs";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="/#about-us">Nous connaître</a></li>

            <li><a href="/#offers">Nos offres</a></li>

            <li><a href="/#expertises">Nos expertises</a></li>

            <li><a href="/#contact">Contactez-nous</a></li>

            <li><a href="https://blog.cockpitio.com/" target="_blank">Blog <BsArrowUpRightSquare /></a></li>
        </ul>
    )
}
export default Nav;
