import React from 'react';
import SidebarTag from "./SidebarTag";

const AboutOVH = () => {
    return (
        <div className="rwt-about-area rn-section-gap" id="about-us">
            <div className="container">

                <div className="row row--30 align-items-center">
                    <div className="col-md-4 .offset-md-4"></div>

                    <div className="col-lg-8 mt_md--40 mt_sm--40 mb--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Notre expertise OVH
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row--30 align-items-center row-top-padding-0">
                    <div className="col-lg-6 offset-md-3">
                        <div className="thumbnail">
                            <SidebarTag />
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOVH;
