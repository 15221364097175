import React from 'react';
import ContactForm from "./ContactForm";
import {FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>

                <div className="col-lg-5">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Adresse</h4>
                                        <p>10 rue de Penthièvre,</p>
                                        <p>75008 Paris</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">E-mail</h4>
                                        <p><a href="mailto:acontact@cockpitio.com">contact@cockpitio.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Téléphone</h4>
                                        <p><a href="tel:+444 555 666 777">+33 6 10 52 42 20</a></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactOne;