import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import {ReactTyped} from "react-typed";
import {GiPaperPlane} from "react-icons/gi";
import Separator from "../elements/separator/Separator";
import Copyright from "../common/footer/Copyright";
import CalltoActionJoinUs from "../elements/calltoaction/CalltoActionJoinUs";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";


const JoinUs = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('light');
    }, []);

    return (
        <>
            <SEO title="Cockpit io | Recrutement Cloud & DevOps" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <CalltoActionJoinUs />

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = ""
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Cockpit io en quelques mots"
                                    description = ""
                                />
                            </div>
                        </div>

                        <div className="row">
                            <ul className="list-icon">
                                <li><span className="icon"><GiPaperPlane /></span>Cockpit io est une entreprise spécialisée dans le cloud et les méthodologies DevOps.</li>
                                <li><span className="icon"><GiPaperPlane /></span>Basée à Paris et présent dans toute la France, nos consultant(e)s apportent des solutions cloud native sur-mesure à nos clients, privés ou publics.</li>
                            </ul>
                        </div>

                        <div className="row row--30">
                            <div className="mission-title text-center">
                                <h2 className="title">Nos valeurs <br/> {" "}
                                    <ReactTyped className="theme-gradient"
                                                strings={[
                                                    "Collectif",
                                                    "Transparence",
                                                    "Équité",
                                                    "Excellence",
                                                ]}
                                                typeSpeed={80}
                                                backSpeed={5}
                                                backDelay={1000}
                                                loop
                                    >
                                    </ReactTyped>
                                </h2>
                            </div>
                        </div>

                        <div className="row">
                            <ul className="list-icon">
                                <li><span className="icon"><GiPaperPlane /></span>Nous mettons nos expertises au service de nos clients, expertises que nous développons et enrichissons quotidiennement.</li>
                                <li><span className="icon"><GiPaperPlane /></span>En rejoignant Cockpit io, vous rejoindrez un environnement stimulant qui privilégie le partage des connaissances et la collaboration. Chaque collaborateur·ice est impliqué·e dans la vie de l’entreprise et dans la co-construction de notre futur commun.</li>
                                <li><span className="icon"><GiPaperPlane /></span>Dans le cadre de notre développement, nous recherchons actuellement des consultant·e·s pour renforcer l’équipe SRE.</li>
                            </ul>
                        </div>

                        <Separator />

                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = ""
                                            radiusRounded = ""
                                            subtitle = ""
                                            title = "Descriptif du poste"
                                            description = ""
                                        />
                                    </div>

                                    <div className="custom-text-style">
                                        <p className="custom-text-style">
                                            En tant que Site Reliability Engineer (SRE), vous serez intégré·e au sein des équipes de nos clients.
                                        </p>
                                        <p className="custom-text-style">
                                            <b><u>Votre rôle sera :</u></b>
                                        </p>
                                        <ul className="list-icon">
                                            <li><span className="icon"><GiPaperPlane /></span>Concevoir, construire et optimiser les architectures Cloud de nos clients</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Concevoir et réaliser des solutions techniques évolutives</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Mener des projets de migration vers le cloud ou vers Kubernetes</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Mener des projets de conteneurisation des applications et des projets serverless</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Définir et mettre en place des usines logicielles et concevoir de pipelines de CI/CD</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Promouvoir les bonnes pratiques Cloud et DevOps auprès de nos clients</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Mettre en place des solutions d’observabilité</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Animer des workshops, des retours d’expérience, des formations (internes ou externes)</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Contribuer à l’émulation technique de Cockpit io, en rédigeant des articles de blogs, en assistant à des événements, en animant des ateliers de partage de connaissance…</li>
                                        </ul>
                                        <blockquote>
                                            Les missions qui vous seront confiées se feront toujours en cohérence avec vos aspirations et nos enjeux.
                                        </blockquote>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Separator />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = ""
                                            radiusRounded = ""
                                            subtitle = ""
                                            title = "Profil recherché"
                                            description = ""
                                        />
                                    </div>
                                    <div className="custom-text-style">
                                        <blockquote>
                                            Vous venez du monde du développement ou de l’ops, vous ne cochez pas toutes les cases, mais vous pensez que ce poste est fait pour vous. <br/>
                                            <b>Rencontrons-nous !</b>
                                        </blockquote>

                                        <p className="custom-text-style">
                                            <b><u>Compétences :</u></b>
                                        </p>
                                        <ul className="list-icon">
                                            <li><span className="icon"><GiPaperPlane /></span>Vous êtes familier avec les méthodologies DevOps</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous avez une première expérience avec un des cloud providers suivants : AWS, Azure ou GCP</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous avez déjà travaillé avec des outils de CI/CD (GitLab, Github Actions, Jenkins…)</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous êtes à l’aise avec les conteneurs et l’orchestration (Docker, Kubernetes…)</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous n’envisagez pas de déployer des infrastructures sans un outil d’infra as code tel que Terraform</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous avez déjà travaillé avec un outil d’observabilité : Prometheus, Grafana, Datadog…</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous savez développer des services backends ou des scripts si besoin</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous êtes familier avec les environnements Linux</li>
                                       </ul>

                                        <p className="custom-text-style">
                                            <b><u>Qualités :</u></b>
                                        </p>
                                        <ul className="list-icon">
                                            <li><span className="icon"><GiPaperPlane /></span>Vous aimez accompagner les clients et proposer des solutions</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous aimez le travail en équipe et le partage des connaissances</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous êtes curieux/euse et vous souhaitez continuer à apprendre et à progresser</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous avez une forte capacité à résoudre les problèmes techniques et architecturaux</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous savez prendre des initiatives</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous savez être critique des outils que vous utilisez</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous disposez de bonnes capacités de communication</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Vous aimez le travail bien fait et vous souhaitez intégrer une équipe de passionné·e·s qui vous donne les moyens d’évoluer,
                                                n’hésitez pas, rejoignez-nous !</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Separator />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = ""
                                            radiusRounded = ""
                                            subtitle = ""
                                            title = "Vos avantages"
                                            description = ""
                                        />
                                    </div>
                                    <div className="custom-text-style">
                                        <ul className="list-icon">
                                            <li><span className="icon"><GiPaperPlane /></span>Des jours dédiés à la veille, sur votre temps de travail</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Des événements internes pour partager des moments de convivialité</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Des événements techniques internes</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Financement de vos certifications et de vos formations</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Un career path qui vous permet d’évoluer selon vos aspirations</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Un budget dédié annuel pour assister aux conférences et aux summits</li>
                                            <li><span className="icon"><GiPaperPlane /></span>Une politique d’entreprise interne qui vous encourage dans vos envies de contribution open source, de participation à des événements en tant que speakers…et bien d’autres choses que nous seront ravi·e·s de vous présenter</li>
                                       </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Separator />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = ""
                                            radiusRounded = ""
                                            subtitle = ""
                                            title = "Processus de recrutement"
                                            description = ""
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 mt--50">
                                            <TimelineTwo classVar="no-gradient"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Separator />

                        <div className="row row--30">
                            <div className="col-lg-12 mt_md--30 mt_sm--30">
                                <div className="mt--50 text-center">
                                    <a className="join-us-btn btn-default btn-icon" href="mailto:join-us@cockpitio.com">join-us@cockpitio.com</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Copyright />

            </main>

        </>
    )
}
export default JoinUs;
