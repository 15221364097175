import React , {useState} from 'react';

const Result = () => {
    return (
        <p className="success-message">Votre message a été envoyé avec succès. Nous reviendrons vers vous prochainement.</p>
    )
}

function ContactForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="/contact"  method="post">
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Nom"
                required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="company"
                    placeholder="Entreprise"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="contact_function"
                    placeholder="Fonction"
                    required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Adresse e-mail"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Téléphone"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Sujet"
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder="Message"
                required
                >
                </textarea>
            </div>

            <div className="form-group text-center">
                <button className="btn-default btn-large">Envoyer</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
