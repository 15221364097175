import React from 'react';

const BrandList = [
    {
        image: './images/expertises/ovhcloud.png',
        alt: 'OVH Cloud logo'
    },
    {
        image: './images/expertises/kubernetes.png',
        alt: 'Kubernetes logo'
    },
    {
        image: './images/expertises/cncf-logo.png',
        alt: 'Prometheus logo'
    },
    {
        image: './images/expertises/aws.png',
        alt: 'AWS logo'
    },
    {
        image: './images/expertises/microsoft-azure.png',
        alt: 'Microsoft Azure logo'
    },
    {
        image: './images/expertises/google-cloud-gcp.png',
        alt: 'Google Cloud Platform (GCP) logo'
    },
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <img src={`${data.image}`} alt={`${data.alt}`} />
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
