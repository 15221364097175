import React from 'react';
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import {FiLinkedin, FiTwitter} from "react-icons/fi";

const Contact = () => {
    return (
        <>
                <div className="main-content" id="contact">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Contactez-nous"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <ul className="social-icon social-default with-bg-primary">
                                        <li><a href="https://twitter.com/cockpitio42" target="_blank" rel="noreferrer"><FiTwitter /></a></li>
                                        <li><a href="https://www.linkedin.com/company/cockpit-io" target="_blank" rel="noreferrer"><FiLinkedin /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
        </>
    )
}
export default Contact;