import React, {useEffect} from 'react';
import {ReactTyped} from 'react-typed';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import Copyright from "../common/footer/Copyright";
import AboutAWS from '../elements/aws/AboutAWS';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import References from "../elements/aws/references";
import {GiPaperPlane} from "react-icons/gi";
import {AiOutlineCloud} from "react-icons/ai";


const AWSPage = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('light');
    }, []);

    return (
        <>
            <SEO title="Cockpit io | Expertes & experts Cloud AWS" />
            <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
            <main className="page-wrapper">

             {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-650">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <img className="text-center mb--30 " src="../images/expertises/cockpit-io-aws.jpg" alt="Cockpit io aws partnership illustration" />
                                    <span className="subtitle">Profitez de notre partenariat pour :</span>
                                    <h1 className="title theme-gradient display-two">{" "}
                                        <ReactTyped
                                            strings={[
                                                "Innover",
                                                "Performer",
                                                "Fidéliser",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        >
                                        </ReactTyped>
                                    </h1>
                                    <p className="description">Bénéficiez de nos expertises, de nos success stories et de notre équipe certifiée pour profiter pleinement de la puissance du cloud AWS !</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                 <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Nos references"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <References
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign = "text-start"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start About Area  */}
                <AboutAWS />
                {/* End About Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rwt-tab-area rn-section-gap" id="offers">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Le serverless en quelques mots"
                                    description = ""
                                />
                            </div>
                        </div>

                        <div className="row">
                            <ul className="list-icon">
                                <li><span className="icon"><GiPaperPlane /></span>Le serverless est un paradigme du Cloud computing où les serveurs sont gérés par les cloud providers.</li>
                                <li><span className="icon"><GiPaperPlane /></span>Le serverless offre de nombreux avantages :</li>
                                <ul>
                                    <li><span className="icon"><AiOutlineCloud /></span>Autonomie des équipes de développement</li>
                                    <li><span className="icon"><AiOutlineCloud /></span>Haute disponibilité</li>
                                    <li><span className="icon"><AiOutlineCloud /></span>Scalabilité</li>
                                    <li><span className="icon"><AiOutlineCloud /></span>Réductions des coûts, des capacités inutilisées, temps de maintenance et time to market</li>
                                </ul>
                                <li><span className="icon"><GiPaperPlane /></span>AWS propose un grand nombre de services serverless, qui permettent de répondre à n’importe quel besoin et qui s’adressent à tous les types de profils : calcul, stockage, bases de données, orchestration, API Gateway...</li>
                                <li><span className="icon"><GiPaperPlane /></span>Parmi les principaux services utilisés : AWS lambda, AWS Fargate, Amazon S3, Amazon API Gateway, Amazon DynamoDB...</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <Copyright />

            </main>
        </>
    )
}
export default AWSPage;
