import React from 'react';
import { FaCloudversify, FaTelegramPlane } from "react-icons/fa";
import { AiOutlineAlert } from "react-icons/ai";
import { IoMdSchool } from "react-icons/io";
import { SiAmazoncloudwatch } from "react-icons/si";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FaCloudversify />,
        title: 'Move to Cloud',
        description: 'Confiez-nous tout ou partie de votre SI à migrer dans le Cloud OVH.'+
                      'On premise, cloud privé ou déjà sur le cloud public, nos expert·es certifié·es vous accompagneront dans ce processus complexe tout en bénéficiant de nos retours d’expériences réussies.'
    },
    {
        icon: <FaTelegramPlane />,
        title: 'Refonte & Optimisations',
        description: 'Vous souhaitez optimiser vos pratiques, rendre votre infrastructure plus performante et résiliente ou tout simplement la mettre à l’état de l’art, nos experts vous accompagneront tant sur les aspects techniques que sur l’optimisation financière et méthodologique de vos pratiques.'
    },
    {
        icon: <SiAmazoncloudwatch />,
        title: 'Conseil',
        description: 'Analyser l’environnement du SI et son éligibilité à migrer vers le Cloud.' +
                     'À l’issue de cette phase, nous vous proposons des axes d’améliorations pour rendre votre SI éligible, un schéma d’architecture cible ainsi que les grandes étapes pour vous permettre une migration réussie. '
    },
    {
        icon: <AiOutlineAlert />,
        title: 'MCO',
        description: 'Nous confier le MCO de votre infrastructure Cloud est la garantie d’une excellence opérationnelle tant sur la gestion quotidienne que sur l’avancée de vos projets.' +
                     'Notre approche est complète et intègre également la documentation, la formation sur les nouveaux outils intégrés.'
    },
    {
        icon: <IoMdSchool />,
        title: 'Formations & Acculturation',
        description: 'Premiers pas dans le Cloud, besoin de renforcer une expertise ou de s’acculturer à de nouveaux services, nous proposons tout type de formats : formations, transferts de compétences, retour d’expérience, workshop...'
    },
]

const References = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper references">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 reference" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default References;